<template>
  <div id="Software">
    <a href="http://laidian360.mikecrm.com/OPfnwxH">
      <img
        src="@/assets/img/project_2.png"
        width="100%"
        height="100%"
      >
    </a>
  </div>
</template>
<script>
export default {
  name: 'Software',
  data () {
    return {}
  }
}
</script>
<style scoped>
</style>
